import React, { useState, Suspense } from "react"
import Header from "./componets/Header"
// import Main from './componets/Main';
/* 지연 로딩을 위해 Main 컴포넌트를 React.lazy로 불러옵니다. */
const Main = React.lazy(() => import("./componets/Main"))
import Footer from "./componets/Footer"
import ToTop from "./componets/ToTop"

import { IntlProvider } from "react-intl"
import enUsMsg from "./lang/en-US.json"
import koMsg from "./lang/ko.json"
import { BrowserRouter, Route, Routes } from "react-router-dom"
// import Dashboard from './pages/Dashboard';
/* 지연 로딩을 위해 Dashboard 컴포넌트를 React.lazy로 불러옵니다. */
const Dashboard = React.lazy(() => import("./pages/Dashboard"))

// 언어의 기본값을 설정하는 것 ---> en-US으로 시작 ---> localeStorage가 잘 가져와지는지 console찍어보기 ---> ko로 바꿔도 안바뀌면 f12눌러서 스토리지 데이터 삭제
// const locale = localStorage.getItem("locale") ?? "en-US";
// console.log(locale);
// const messages = { "en-US": enUsMsg, ko: koMsg }[locale];
// console.log(messages);

const App = () => {
  const defaultLocale = "en-US" // 기본 언어 설정
  const [locale, setLocale] = useState(defaultLocale)
  const messages = { "en-US": enUsMsg, ko: koMsg }[locale]

  return (
    <IntlProvider locale={locale} messages={messages}>
      {/* Intlprovider 써서 전체 적으로 번역 값이 들어가게 젤 크게 싸주자 */}
      {/* 국제화 기능을 사용하려는 최상위 컴포넌트에서 IntlProvider 컴포넌트를 사용해야 합니다. */}
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        {/* Suspense로 lazy loading 컴포넌트 로딩 시 fallback UI 표시 */}
        <Suspense fallback={<div>로딩 중...</div>}>
          <Routes>
            <Route path="/" element={<Main />}></Route>
            <Route path="/dashboard" element={<Dashboard />}></Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </IntlProvider>
  )
}

export default App
